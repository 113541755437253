/*================
======BODY======
==================*/

body,
html {
  background: $background;
}

.carousel-fade .carousel-item {
  transition: all 0.9s ease-in-out;
}

nav {
  font-size: 0.9rem;
}

a {
  color: rgb(126, 126, 126);
  transition: all 0.4s ease-in-out;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

.carousel {
  overflow: hidden;
}

.table {
  color: rgb(182, 182, 182)!important;
}

.btn-outline-secondary {
    color: #a4a7aa;
    border-color: #a4a7aa;
}

.modal-content {
  background-color: rgba(32, 32, 32, 0.92)!important;
  border: 1px solid rgba(69, 69, 69, 0.46);
}
.modal-header {
  border-bottom: 1px solid #4f4f4f!important;
  color:$color;
}

.modal-footer {
  border-top: 1px solid #4f4f4f!important;
}

.close {
  color:#fff!important;
}

/*================
======NAVBAR======
==================*/

.social-head {
  padding-right: 1rem;
}

.navbar-brand img {
  width: 100px;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color:$gelb!important;
  //border-bottom: 2px solid #f9c100;
}

.dropdown-item.active,
.dropdown-item:active {
  color: $red!important;
  background-color: #222;
  border: 1px solid #aaa;
}

.dropdown-divider {
  border-top: 1px solid rgba(255,255,255,.05);
}

nav .dropdown-item {
  background-color: rgba(43,43,43,.952941);
  color: $color;
}

.navbar-expand-xl .navbar-nav .dropdown-menu {
  border: 1px solid rgba(255,255,255,.05);
}

nav .dropdown-item:hover {
  color: $red!important;
  background-color: rgba(43,43,43,.952941);
  border: 1px solid #aaa;
}

nav .dropdown-menu {

  background-color: $background !important;
  opacity: 0.95 !important;
}

.navbar {
  padding: 0.5rem;
}

.fixed-top {
  border-bottom: 1px solid rgba(255,255,255,.1)!important;
}

.navbar-light .navbar-toggler {
  color: $color;
  border-color: $theme-color;
}

.bar-icon {
  font-size: 1.5rem;
}

.navbar-brand {
  margin-left: 7rem;
}

.login-head a {
  color: $color;
  transition: all 0.9s ease 0s;
}

ul.navbar-nav {
  background-color: rgba(34, 34, 34, 0.95)!important;
  padding-left: 1rem;
  text-align: center;
}

.login-head a:hover {
  color: $theme-color;
}

.bg-light {
  background-color: $background !important;
  opacity: 0.95 !important;
  border-bottom: 2px solid rgba(221, 221, 221, 0.16);
}

.nav-link {
  color: $color!important;
  transition: all 0.5s ease-in-out;
  font-weight: 500;
  text-transform: uppercase;
}

.navbar-expand-xl .navbar-nav .nav-link {
  //padding-right: 0.6rem;
  //padding-left: 0.6rem;
  //margin: 0.1rem;
  //font-size: 0.95rem;
  //font-weight: 600;
}

.nav-link .fa b {
  font-family: $font1;
  font-weight: 500;
  text-transform: uppercase;
}

.nav-link:hover {
  color: $red!important;
  background-color: $background;
}

@media screen and (max-width: 1200px) {
  .navbar-brand {
    display: none;
  }

  .fixed-top {
    height: 4.1rem;
  }

  .social-head {
    padding-right: 0.5rem;
    position: absolute;
    right: 0;
  }
}
