
.hr-white {
    margin-top: 1rem!important;
    margin-bottom: 1rem!important;
    border: 0!important;
    border-top: 1px solid rgba(255, 255, 255, 0.67)!important;
}

.hr-white-opt {
    margin-top: 0!important;
    margin-bottom: 0!important;
    border: 0;
    border-top: 1px solid hsla(0, 0%, 68%, 0.37);
}

.box-shadow {
  box-shadow: inset -1px -2px 60px 12px rgba(255,255,255,.2);
}

/*====================
======SCROLLUP=====
======================*/

i.fa-bglight.active {
  color: #fff;
  text-align: center;
  background: var(--gray);
  border-radius: 50%;
}

.fa-square.fa-2x {
  width: 48px!important;
  height: 48px!important;
  line-height: 48px!important;
}

a.scrollup {
  text-decoration: none;
  outline: 0;
  display: none;
}

.scrollup {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 32px;
  height: 32px;
  z-index: 99999;
}
/*=====================
======SOCIAL ICONS=====
======================*/
.socials i {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 3px;
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    transition: all .5s ease-in-out;
}

.socials .fa-facebook {
  color: rgba(255, 255, 255, 0.5);
}

.socials .fa-pinterest-p {
  color: rgba(255, 255, 255, 0.5);
}

.socials .fa-behance {
  color: #fff;
  background-color: rgb(59, 154, 207);
}

.socials .fa-instagram {
  color: rgba(255, 255, 255, 0.5);
}

.socials .fa-instagram:hover {
  color:white;
  border-color: #fff;
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

.socials .fa-pinterest-p:hover {
background-color: #c8232c;
color:white;
border-color: #fff;
}

.socials .fa-facebook:hover {
  color: #fff;
  background: #4267b2;
  border-color: #fff;
}

.socials .fa-twitter:hover {
  color: #38A1F3;
  background: #fff;
  border-color: #38A1F3;
}

.socials .fa-behance:hover {
  color: rgb(59, 154, 207);
  background-color: #fff;
  border-color: rgb(59, 154, 207);
}

/*====================
======Snackbar=====
======================*/

#snackbar-success {
  visibility: hidden;
  min-width: 300px;
  margin-left: -125px;
  background-color: rgba(55, 132, 55, 1);
  border: 2px solid #fff;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 9999;
  left: 50%;
  bottom: 30px;
}

#snackbar-success.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
#snackbar-warning {
  visibility: hidden;
  min-width: 300px;
  margin-left: -125px;
  background-color: #d73814;
  border: 2px solid #fff;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 9999;
  left: 50%;
  bottom: 30px;
}

#snackbar-warning.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}
@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}
@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
