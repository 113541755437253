/*================
======START=======
==================*/
#start h1 {
  font-size: 1.75rem;
}

section#start {
  padding-top: 4.2rem;
}

.btn-main {
  margin-top: 1rem;
  margin-left: 9.2rem;
  color: rgb(190, 190, 190);
  overflow: hidden;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid rgb(190, 190, 190);
  padding: 0.6rem 1.2rem;
  transition: all 0.4s ease-in-out;
  width: 50%;
}

.btn-main a:hover,
.btn-main:hover {
  color: #fff;
  border-color: #fff;
}

.carousel-caption {
  padding-bottom: 4rem;
}

#start .aniamtion-logo {
  position: absolute;
  top: 1%;
  right: 15%;
  width: 30%;
  color: #fff;
}

#start .aniamtion-logo h1,
.box-img {
  padding-left: 2rem;
  opacity: 1;
  animation: animate_h1 4s cubic-bezier(0,0.23,1,.1);
}

.btn-main {
  opacity: 1;
  animation: animate_h1 5s cubic-bezier(0,0.23,1,.1);
}

#start .aniamtion-logo h1 small {
  color: rgb(130, 130, 130);
  padding-left: 0.5rem;
}

.yellow-strich {
  display: block;
  height: 0.2rem;
  width: 30rem;
  margin: 0.5rem 0;
  background-color: #f9c100;
}

.box-img {
  padding: 2rem 0 3rem 11rem;
}

.box-img img {
  width: 100px;
}
@keyframes animate_h1 {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
/*===================
======ANIMATION======
=====================*/
ellipse,
path,
polygon,
rect {
  stroke: #fff;
  fill: #fff;
  stroke-dasharray: 1800;
  opacity: 10;
  animation: animate 3s cubic-bezier(0,0.23,1,.1);
  //animation-iteration-count: 2;
}
@keyframes animate {
  0% {
    opacity: 0;
    fill: none;
    stroke-dashoffset: 1800;
  }

  30% {
    opacity: 0;
    fill: none;
    stroke-dashoffset: 1800;
  }

  90% {
    fill: rgba(255, 255, 255, 0);
  }

  100% {
    fill: rgba(255, 255, 255, 1);
    stroke-dashoffset: 0;
  }
}

.dot {
  background: rgb(116, 116, 116);
  border-radius: 1500px;
  position: absolute;
  animation: fadein 1s cubic-bezier(0.42,0,0.58,1);
  transform: translate(100%, -100%);
  left: 20rem;
  animation-iteration-count: 3;
  //bottom: 5rem;
}
@keyframes fadein {
  from {
    width: 0;
    height: 0;
    opacity: 1;
  }

  to {
    width: 700px;
    height: 700px;
    opacity: 0;
  }
}
/*================
======QUERIS======
==================*/
@media screen and (max-width: 1400px) {
  #start .aniamtion-logo {
    top: 1%;
    right: 5%;
    width: 45%;
  }

  .box-img img {
    width: 90px;
  }

  .box-img {
    padding: 1rem 0 2rem 14rem;
  }

  .btn-main {
    margin-top: -1rem;
  }
}
@media screen and (max-width: 1200px) {
  #start h1 {
    font-size: 1.3rem;
  }

  .box-img {
    display: none;
  }

  #start .aniamtion-logo {
    top: 5%;
    right: 5%;
    width: 45%;
  }

  .btn-main {
    margin: 5rem 0 0 2rem;
  }
}
@media screen and (max-width: 992px) {
  section#start {
    padding-top: 3.2rem;
  }

  #start .aniamtion-logo {
    position: absolute;
    top: 10%;
    right: 5%;
    width: 35%;
    color: #fff;
  }

  .btn-main {
    margin: 3rem 0 0 2rem;
  }
}
@media screen and (max-width: 768px) {
  #start .aniamtion-logo {
    top: 35%;
    right: 5%;
    width: 85%;
  }

  .carousel-item .w-100 {
    width: unset!important;
  }

  #start .aniamtion-logo h1,
  .box-img {
    padding-left: unset;
  }

  .yellow-strich {
    width: unset;
  }
}
@media screen and (max-width: 576px) {
  #start .w-100 {
    width: 150%!important;
  }
}
/*================
======EUPHIRIA====
==================*/
#euphoria,
#konzept,
#chassis,
#weiche,
#accessories,
#daten,
#form,
#footer  {
  background-image: url("../images/background/bsw.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50% 50%;
  width: 100%;
  padding: 2rem 0.5rem;
  color: rgb(182, 182, 182);
  border-top: 1px solid rgba(190, 190, 190,.2);
}

#chassis h1,
#euphoria h1,
#konzept h1,
#weiche h1,
#accessories h1,
#daten h1,
#form h1 {
  text-transform: uppercase;
}

.sm-box-wrapper {
  padding: 3rem 0;
  border-radius: 0.5rem;
  border: 1px solid rgba(255,255,255,0.3);
}

.box-sm {
  padding: 1rem;
  color: rgb(97, 97, 97);
}

#konzept {
  //background-color: #000;
  border-top: 1px solid rgba(190, 190, 190,.2);
  padding: 2rem 0.5rem;
  color: rgb(182, 182, 182);
}

#konzept img {
  border-radius: 2rem;
  overflow: hidden;
  padding: 1rem;
}

#chassis {
  border-top: 1px solid rgba(190, 190, 190,.2);
  padding: 2rem 0.5rem;
  color: rgb(182, 182, 182);
}

#weiche {
  border-top: 1px solid rgba(190, 190, 190,.2);
  padding: 2rem 0.5rem;
  color: rgb(182, 182, 182);
}
#chassis .img-round {
  border-radius: 40%;
  width: 20rem;
}
#accessories {
  border-top: 1px solid rgba(190, 190, 190,.2);
  padding: 2rem 0.5rem;
  color: rgb(182, 182, 182);
}

#daten {
  border-top: 1px solid rgba(190, 190, 190,.2);
  padding: 2rem 0.5rem;
  color: rgb(182, 182, 182);
}
#accessories img {
  width: 20rem;
}

#daten .fa-gears {
  font-size: 12rem;
  color: rgba(255,255,255,0.2);
}

#form {
border-top: 1px solid rgba(190, 190, 190,.2);
padding: 2rem 0.5rem;
color: rgb(182, 182, 182);
}
.form-wrapper {
  margin:5rem;
}

#form .fa-asterisk {
  font-size: 0.7rem;
}

#form .img-round {
  border-radius: 50%;
  cursor: pointer;
  width: 9rem;
  transition: all 0.4s ease-in-out;
  padding:0.3rem;
  overflow: hidden;
}

#form .select-default {
  border:  0.2rem solid rgba(190, 190, 190,.2);
}

.footer-box {
  padding: 3rem 1rem;
}

.carousel-control-next, .carousel-control-prev {
width: unset;
}

.textur-option {
  min-height: 15rem;
}

.carousel-item .text-name {
  cursor: pointer;
  transition: all 450ms;
}

.carousel-item .text-name:hover {
  color:$gelb;
}
@media screen and (max-width: 992px) {
  #konzept h1, #chassis h1, #weiche h1, #accessories h1, #daten h1, #form h1 {
    padding-top: 2rem;
    font-size: 1.6rem;
  }
}
